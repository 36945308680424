<template>
  <div class="all_page">
    <div class="top_type">
      <div
        @click="topAction(index)"
        :class="{ top_cont: true, select_con: selectIndex == index }"
        v-for="(item, index) in setData"
        :key="index"
      >
        <p>{{ item.name }}</p>
        <div v-if="selectIndex == index" class="line"></div>
      </div>
    </div>
    <div class="cemter">
      <div class="Home_order" v-if="HomeList.length > 0">
        <van-list
          v-model="loading"
          :finished="finished"
          finished-text="没有更多了"
          @load="onLoad"
          :immediate-check="false"
        >
          <div v-for="(item,index) in HomeList" :key="index" class="list_style">
            <div class="list_left">
              <span>{{ item.handle }}</span>
              <p>{{ item. add_time}}</p>
            </div>
            <div class="left_right" v-if="item.type==1">+{{ item.points }}</div>
            <div class="left_rightblur" v-else>+{{ item.points }}</div>
          </div>
        </van-list>
      </div>
      <div v-else class="Home_order_No g-flex-ac">
        <div class="order_No m-at">
          <img src="../../assets/images/home/zu8.png" alt="" />
          <span class="text fs-24">暂无积分明细</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "",
  mixins: [],
  components: {},
  props: {},
  data() {
    return {
      setData: [
        {
          name: "全部积分",
          id: "",
        },
        {
          name: "获取积分",
          id: 1,
        },
        {
          name: "积分兑换",
          id: 2,
        },
      ],
      selectIndex: 0,
      HomeList: [
        {
          id: 1,
        },
      ],
      params: {
        type: "", //1 获取积分 2 积分兑换
        page: 1, //页码
        limit: 20, //每页条数
      },
      loading: false,
      finished: false,
    };
  },
  computed: {},
  watch: {},
  created() {this.onLoad(1);},
  methods: {
    topAction(index) {
      this.selectIndex = index;
      this.onLoad(1);
    },
    onLoad(val) {
      console.log(val);
      if (val) {
        this.params.page = val;
        this.HomeList = [];
      }
      this.params.type = this.setData[this.selectIndex].id;
      this.$http
        .getpointsLogList(this.params)
        .then((res) => {
          console.log(res, "onload");
          this.loading = false;
          this.finished = res.count.page >= res.count.pageAll;
            this.HomeList.push(...res.data);
          this.params.page++;
        })
        .catch(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style scoped lang="less">
.left_right {
  font-family: PingFang SC;
  font-weight: bold;
  font-size: 30px;
  color: #e86161;
}
.left_rightblur{
font-family: PingFang SC;
font-weight: bold;
font-size: 30px;
color: #577BB5;
}
.list_left {
  span {
    font-family: PingFang SC;
    font-weight: 500;
    font-size: 28px;
    color: #333333;
  }
  p {
    font-family: PingFang SC;
    font-weight: 400;
    font-size: 26px;
    color: #999999;
  }
}
.list_style {
  padding: 0 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 auto 20px auto;
  width: 700px;
  height: 130px;
  background: #ffffff;
  border-radius: 20px;
}
.cemter {
  height: calc(100vh - 88px);
}
.Home_order {
  padding-top: 108px;
  box-sizing: border-box;
  height: calc(100vh - 88px);
  // .van-list{
  //   overflow: auto;
  //   height: calc(100vh - 188px) !important;
  // }
}
.Home_order_No {
  width: 100%;
  min-height: 500px;
  .order_No {
    width: 134px;
    text-align: center;
    img {
      width: 134px;
      height: 174px;
    }
    .text {
      color: #666;
    }
  }
}
.line {
  position: absolute;
  bottom: 0;
  width: 50px;
  height: 5px;
  background: #577bb5;
  border-radius: 3px;
}
.top_cont {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 88px;
  position: relative;
  font-family: PingFang SC;
  font-weight: 500;
  font-size: 30px;
  color: #666666;
}
.select_con {
  font-family: PingFang SC;
  font-weight: 800;
  font-size: 34px;
  color: #577bb5;
}
.all_page {
  overflow: auto;
  width: 100vw;
  min-height: 100vh;
  background: #f8f8f8;
}
.top_type {
  position: fixed;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 750px;
  height: 88px;
  background: #ffffff;
}
</style>
